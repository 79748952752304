<template>
  <div>
    <div class="event-title">
      <vca-row>
        <h3>{{ event.name }}</h3>
        <div class="vca-right">
          <img
            :src="event.eventIcon"
            :title="$t('events.eventtypes.' + event.type_of_event + '.title')"
            :alt="$t('events.eventtypes.' + event.type_of_event + '.title')"
          />
          <vca-share-icon
            v-if="event.isPublished"
            :url="event.shareURL"
            :text="
              $t('events.share.text', {
                0: event.name,
              })
            "
          ></vca-share-icon>
        </div>
      </vca-row>
      <div class="event-subtitle">
        {{ $t("events.eventtypes." + event.type_of_event + ".title") }}
      </div>
    </div>
    <vca-row v-if="event.type_of_event != 'crew_meeting'">
      <div>
        <h4>{{ $t("events.time.application_period") }}:</h4>
        {{ applicationPeriod }}
      </div>
      <div>
        <h4>{{ $t("events.time.event_period") }}:</h4>
        {{ eventPeriod }}
      </div>
    </vca-row>
    <vca-row>
      <div>
        <h4>{{ $t("events.crew.label") }}:</h4>
        <span v-if="event.crew.name != ''">{{ event.eventCrew }}</span>
        <span v-else>{{ $t("events.list.crew.office_hh") }}</span>
      </div>
      <div v-if="event.type_of_event != 'crew_meeting'">
        <h4>{{ $t("events.artists") }}:</h4>
        {{ event.eventArtists }}
      </div>
    </vca-row>
    <vca-row v-if="session && eventASP">
      <div>
        <h4>{{ $t("events.event_asp.name.label") }}:</h4>
        <div>
          {{ eventASP.full_name }}

          <div v-if="applicationStatus == 'confirmed'">
            <div v-if="eventASP.email">
              {{ $t("events.event_asp.email.label") }}:
              {{ eventASP.email }}
            </div>
            <div v-if="eventASP.phone">
              {{ $t("events.event_asp.phone.label") }}:
              {{ eventASP.phone }}
            </div>
            <div v-if="eventASP.mattermost_username">
              {{ $t("events.event_asp.mattermost_username.label") }}:
              {{ eventASP.mattermost_username }}
            </div>
          </div>
        </div>
      </div>
      <vca-column>
        <div>
          <h4>{{ $t("events.location.title") }}:</h4>
          {{ event.eventLocation }}
        </div>
        <div
          v-if="
            (applicationStatus == 'confirmed' ||
              (user && event.event_asp_id == user.id)) &&
            event_details.meeting_url
          "
        >
          <h4>{{ $t("events.meeting_url.label") }}:</h4>
          {{ event_details.meeting_url }}
        </div>
      </vca-column>
    </vca-row>
    <vca-row v-else>
      <div>
        <h4>{{ $t("events.event_asp.name.label") }}:</h4>
        {{ $t("events.event_asp.hidden") }}
      </div>
      <vca-column>
        <div>
          <h4>{{ $t("events.location.title") }}:</h4>
          {{ event.eventLocation }}
        </div>
        <div
          v-if="
            (applicationStatus == 'confirmed' ||
              (user && event.event_asp_id == user.id)) &&
            event_details.meeting_url
          "
        >
          <h4>{{ $t("events.meeting_url.label") }}:</h4>
          {{ event_details.meeting_url }}
        </div>
      </vca-column>
    </vca-row>
    <vca-row>
      <div>
        <h4>{{ $t("events.website.label") }}:</h4>
        {{ event.eventWebsite }}
      </div>
    </vca-row>
    <div v-if="event.type_of_event != 'crew_meeting'">
      <h4>{{ $t("events.tools.label") }}</h4>

      {{ getToolsList }}
    </div>
    <div>
      <h4>
        {{ $t("events.additional.label") }}
      </h4>
      <div class="formatted">
        {{
          event.additional_information != ""
            ? event.additional_information
            : "-"
        }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "EventApplicationDetails",
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      event_details: {},
    };
  },
  created() {
    if (this.applicationStatus == "confirmed" || this.hasSystemPermission()) {
      this.$store
        .dispatch({ type: "events/getDetails", data: this.event.id })
        .then((response) => {
          this.event_details = response;
        });
    } else if (
      this.event &&
      this.user &&
      this.event.event_asp_id == this.user.id
    ) {
      this.event_details = {
        meeting_url: this.event.meeting_url,
        event_asp: this.event.event_asp,
      };
    }
  },
  computed: {
    ...mapGetters({
      session: "session",
      user: "user/current",
      hasSystemPermission: "user/roles/hasSystemPermission",
      user_participations: "events/participations/list",
    }),
    applicationStatus() {
      return this.userApplication ? this.userApplication.status : null;
    },
    eventASP() {
      return this.hasCrew && this.event_details
        ? this.event_details.event_asp
        : this.internal_asp;
    },
    internal_asp() {
      return this.event.internal_asp
        ? {
            full_name: this.event.internal_asp.full_name,
            display_name: this.event.internal_asp.display_name,
            email: this.event.internal_asp.email,
            phone: this.event.internal_asp.profile.phone,
            mattermost_username:
              this.event.internal_asp.profile.mattermost_username,
          }
        : null;
    },
    hasCrew() {
      return this.event.crew && this.event.crew.id;
    },
    userApplication() {
      return this.user_participations
        ? this.user_participations.find((el) => el.event_id == this.event.id)
        : [];
    },
    eventPeriod() {
      return !(this.event != undefined)
        ? ""
        : this.datetime(this.event.start_at) +
            " - " +
            this.datetime(this.event.end_at);
    },
    applicationPeriod() {
      return !(this.event != undefined && this.event.application)
        ? " - "
        : this.date(this.event.application.start_date) +
            " - " +
            this.date(this.event.application.end_date);
    },
    getToolsList() {
      if (!(this.event != undefined && this.event.event_tools)) {
        return "";
      }

      let tools = this.event.event_tools.tools
        .filter((el) => {
          return el != "special";
        })
        .map((el) => this.$t("events.tools.list." + el))
        .join(", ");
      if (this.event.event_tools.special) {
        tools += tools
          ? ", " + this.event.event_tools.special
          : this.event.event_tools.special;
      }
      return tools;
    },
  },
};
</script>
