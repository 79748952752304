var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"event-title"},[_c('vca-row',[_c('h3',[_vm._v(_vm._s(_vm.event.name))]),_c('div',{staticClass:"vca-right"},[_c('img',{attrs:{"src":_vm.event.eventIcon,"title":_vm.$t('events.eventtypes.' + _vm.event.type_of_event + '.title'),"alt":_vm.$t('events.eventtypes.' + _vm.event.type_of_event + '.title')}}),(_vm.event.isPublished)?_c('vca-share-icon',{attrs:{"url":_vm.event.shareURL,"text":_vm.$t('events.share.text', {
              0: _vm.event.name,
            })}}):_vm._e()],1)]),_c('div',{staticClass:"event-subtitle"},[_vm._v(" "+_vm._s(_vm.$t("events.eventtypes." + _vm.event.type_of_event + ".title"))+" ")])],1),(_vm.event.type_of_event != 'crew_meeting')?_c('vca-row',[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.time.application_period"))+":")]),_vm._v(" "+_vm._s(_vm.applicationPeriod)+" ")]),_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.time.event_period"))+":")]),_vm._v(" "+_vm._s(_vm.eventPeriod)+" ")])]):_vm._e(),_c('vca-row',[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.crew.label"))+":")]),(_vm.event.crew.name != '')?_c('span',[_vm._v(_vm._s(_vm.event.eventCrew))]):_c('span',[_vm._v(_vm._s(_vm.$t("events.list.crew.office_hh")))])]),(_vm.event.type_of_event != 'crew_meeting')?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.artists"))+":")]),_vm._v(" "+_vm._s(_vm.event.eventArtists)+" ")]):_vm._e()]),(_vm.session && _vm.eventASP)?_c('vca-row',[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.event_asp.name.label"))+":")]),_c('div',[_vm._v(" "+_vm._s(_vm.eventASP.full_name)+" "),(_vm.applicationStatus == 'confirmed')?_c('div',[(_vm.eventASP.email)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("events.event_asp.email.label"))+": "+_vm._s(_vm.eventASP.email)+" ")]):_vm._e(),(_vm.eventASP.phone)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("events.event_asp.phone.label"))+": "+_vm._s(_vm.eventASP.phone)+" ")]):_vm._e(),(_vm.eventASP.mattermost_username)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("events.event_asp.mattermost_username.label"))+": "+_vm._s(_vm.eventASP.mattermost_username)+" ")]):_vm._e()]):_vm._e()])]),_c('vca-column',[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.location.title"))+":")]),_vm._v(" "+_vm._s(_vm.event.eventLocation)+" ")]),(
          (_vm.applicationStatus == 'confirmed' ||
            (_vm.user && _vm.event.event_asp_id == _vm.user.id)) &&
          _vm.event_details.meeting_url
        )?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.meeting_url.label"))+":")]),_vm._v(" "+_vm._s(_vm.event_details.meeting_url)+" ")]):_vm._e()])],1):_c('vca-row',[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.event_asp.name.label"))+":")]),_vm._v(" "+_vm._s(_vm.$t("events.event_asp.hidden"))+" ")]),_c('vca-column',[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.location.title"))+":")]),_vm._v(" "+_vm._s(_vm.event.eventLocation)+" ")]),(
          (_vm.applicationStatus == 'confirmed' ||
            (_vm.user && _vm.event.event_asp_id == _vm.user.id)) &&
          _vm.event_details.meeting_url
        )?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.meeting_url.label"))+":")]),_vm._v(" "+_vm._s(_vm.event_details.meeting_url)+" ")]):_vm._e()])],1),_c('vca-row',[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.website.label"))+":")]),_vm._v(" "+_vm._s(_vm.event.eventWebsite)+" ")])]),(_vm.event.type_of_event != 'crew_meeting')?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t("events.tools.label")))]),_vm._v(" "+_vm._s(_vm.getToolsList)+" ")]):_vm._e(),_c('div',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("events.additional.label"))+" ")]),_c('div',{staticClass:"formatted"},[_vm._v(" "+_vm._s(_vm.event.additional_information != "" ? _vm.event.additional_information : "-")+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }