<template>
  <div>
    <vca-column class="event-card" :class="'event-' + event.type_of_event">
      <div>
        <div class="event-title">
          <vca-row>
            <h3>
              {{ event.name }}
              <span v-if="event.crew.name != ''">({{ event.crew.name }})</span>
            </h3>
            <div class="vca-right short">
              <img
                :src="expandedEvent.eventIcon"
                :title="expandedEvent.type_of_event_translation"
                :alt="expandedEvent.type_of_event_translation"
              />

              <img
                :class="{ editable: event.location.name }"
                @click="showMap = true"
                v-if="event.hasLocation"
                src="@/assets/icons/map.png"
                :title="$t('button.map')"
                :alt="$t('button.map')"
              />
              <vca-share-icon
                v-if="event.isPublished"
                :url="event.shareURL"
                :text="
                  $t('events.share.text', {
                    0: event.name,
                  })
                "
              ></vca-share-icon>
            </div>
          </vca-row>
          <p class="event-subtitle">
            {{ event.type_of_event_translation }}
          </p>
        </div>
        <p>
          <span class="bold">{{ $t("table.header.state") }}:</span>
          <span> {{ expandedEvent.event_state_text }}</span>
        </p>
        <p>
          <span class="bold">{{ $t("events.crew.label") }}:</span><br />{{
            event.crew.name
          }}
        </p>
        <p>
          <span class="bold">{{ $t("events.location.title") }}:</span><br />{{
            expandedEvent.eventLocation
          }}
        </p>
        <p>
          <span class="bold">{{ $t("events.time.event_period") }}:</span
          ><br />{{ eventPeriod }}
        </p>
        <div style="visibility: hidden">
          <div class="vca-center">
            <button
              class="vca-button-small"
              :title="$t('button.show')"
              @click="setCurrent(event)"
            >
              {{ $t("button.show") }}
            </button>
            <button
              v-if="canEdit || isEventAsp(event)"
              :title="$t('button.edit')"
              class="vca-button-small"
              @click="setCurrentEdit(event)"
            >
              {{ $t("button.edit") }}
            </button>
          </div>
        </div>
      </div>
      <div class="event-card-buttons">
        <div class="vca-center">
          <button
            class="vca-button-small"
            :title="$t('button.show')"
            @click="setCurrent(event)"
          >
            {{ $t("button.show") }}
          </button>
          <button
            v-if="canEdit || isEventAsp(event)"
            :title="$t('button.edit')"
            class="vca-button-small"
            @click="setCurrentEdit(event)"
          >
            {{ $t("button.edit") }}
          </button>
        </div>
      </div>
    </vca-column>
    <vca-popup
      :show="showMap && event.location.place_id != ''"
      :title="$t('events.map')"
      @close="showMap = false"
    >
      <EventMap v-model="event" />
    </vca-popup>
  </div>
</template>
<script>
import EventMap from "@/components/events/EventMap";
import { mapGetters } from "vuex";
export default {
  name: "EventCard",
  components: { EventMap },
  data() {
    return {
      showMap: false,
      mappedLocation: [
        {
          ...this.event.location,
          ...{
            title: this.event.name,
            subtitle: this.event.type_of_event_translation,
          },
        },
      ],
    };
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  computed: {
    canEdit() {
      return (
        this.hasSystemPermission() ||
        (this.hasPoolPermission(this.poolEventPermissions) &&
          this.user.crew.crew_id == this.event.crew.id)
      );
    },
    eventPeriod() {
      return (
        this.datetime(this.event.start_at) +
        " - " +
        this.datetime(this.event.end_at)
      );
    },
    applicationPeriod() {
      return (
        this.date(this.event.application.start_date) +
        " - " +
        this.date(this.event.application.end_date)
      );
    },
    expandedEvent() {
      let row = {};
      row["shareURL"] = "/events/" + this.event.id;
      row["eventIcon"] = require("@/assets/icons/icon-" +
        this.event.type_of_event +
        ".png");
      row["eventArtists"] =
        this.event.artists && this.event.artists.length > 0
          ? this.event.artists.map((el) => el.name).join(", ")
          : " - ";
      row["eventLocation"] = this.event.location.name
        ? this.event.location.name + ", " + this.event.location.city
        : " - ";
      row["eventWebsite"] = this.event.website ? this.event.website : " - ";
      row["isPublished"] = this.publishedStates.includes(
        this.event.event_state.state
      );

      let mappedState = this.event.event_state.state;
      if (this.event.event_state.state == "requested") {
        mappedState =
          this.event.event_state.crew_confirmation == ""
            ? "requested_crew"
            : "requested_internal";
      }
      row["mappedState"] = mappedState;
      row["event_state_text"] = this.$t(
        "events.list.event_states." + mappedState
      );
      if (
        this.hasSystemPermission() &&
        this.event.event_state.state == "closed"
      ) {
        row["event_state_text"] = this.$t("events.list.event_states.closed");
      }
      row["type_of_event_translation"] = this.$t(
        "events.eventtypes." + this.event.type_of_event + ".label"
      );
      if (this.event.event_state.state == "requested") {
        row["event_state_text"] =
          this.event.event_state.internal_confirmation == ""
            ? this.$t("events.list.event_states.requested_internal")
            : this.$t("events.list.event_states.requested_crew");
      }
      row["eventCrew"] = "dff";
      //this.event.crew && this.event.crew.name != ''
      //    ? this.event.crew.name
      //    : this.$t('events.list.crew.office_hh');

      return {
        ...this.event,
        ...row,
      };
    },
    ...mapGetters({
      user: "user/current",
      publishedStates: "events/publishedstates",
      hasPoolPermission: "user/roles/hasPoolPermission",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
  methods: {
    isEventAsp(value) {
      return (
        this.user && value.event_asp_id && this.user.id == value.event_asp_id
      );
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("events/current", null);
      } else {
        this.$store.commit("events/current", value);
      }
    },
    setCurrentEdit(value) {
      this.$router.push({ path: "/events/edit/" + value.id });
    },
  },
};
</script>
